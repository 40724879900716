<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ form.id ? '新增生产绩效方案' : '生产绩效方案详情' }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <div class="fullScreenMainCon">
        <el-tabs v-model="activeName" class="fullScreenMainHeader">
          <el-tab-pane label="绩效分配">
            <el-row style="margin-bottom: 25px">
              <span
                >项目名称：
                <el-input
                  @focus="addFn"
                  :disabled="ProjectDisable"
                  style="width: 37%"
                  v-model="form.projectName"
                ></el-input>
              </span>
              <span style="margin-left: 15px" v-show="form.submitName"
                >提交人：
                <el-input disabled style="width: 15%" v-model="form.submitName"></el-input>
              </span>
              <span style="margin: 15px; color: #000">
                绩效总金额：
                <span class="Total_amount">{{ form.productPerformance | thousands }}</span>
              </span>
            </el-row>

            <el-table border :data="form.productPerformanceDetailList">
              <el-table-column align="center" type="index" label="序号" width="70">
              </el-table-column>
              <el-table-column align="center" prop="staffName" label="姓名"> </el-table-column>
              <el-table-column align="center" prop="postName" label="部门岗位">
                <!-- <template slot-scope="scope">
                {{ scope.row.postId | dict(dictData.projectYear) }}
              </template> -->
              </el-table-column>
              <el-table-column align="center" prop="ratio" label="比例占比(%)">
                <template slot-scope="scope">
                  <div>
                    <el-input
                      :disabled="disabled"
                      v-model="scope.row.ratio"
                      @input="inputFn(scope.row, scope.$index)"
                    >
                    </el-input>
                  </div>
                  <div v-show="isNum" style="font-size: 14px; color: red; white-space: nowrap">
                    总占比例不能大于100%
                  </div>
                  <div v-show="isnum" style="font-size: 14px; color: red; white-space: nowrap">
                    总占比例不能小于100%
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" prop="ratioContent" label="比例占比说明" width="500">
                <template slot-scope="scope">
                  <el-input
                    type="textarea"
                    autosize
                    :disabled="disabled"
                    v-model="scope.row.ratioContent"
                  >
                  </el-input>
                </template>
              </el-table-column>

              <el-table-column align="center" prop="staffHour" label="	员工工时"></el-table-column>
              <el-table-column
                align="center"
                v-if="options.editIndex == 4 || options.editIndex == 3"
                prop="payedPerformance"
                label="已发放绩效金额(元)"
              >
                <template slot-scope="scope">
                  {{ scope.row.payedPerformance | thousands }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="staffProductPerformance" label="绩效金额(元)">
                <template slot-scope="scope">
                  {{ scope.row.staffProductPerformance | thousands }}
                </template>
              </el-table-column>
            </el-table>

            <el-row style="margin: 10px 0" v-if="options.editIndex == 4">
              <el-col :span="18">
                <div class="grid-content bg-purple" style="opacity: 0">1</div>
              </el-col>
              <el-col :span="6"
                ><div class="grid-content bg-purple-light">
                  <span>生产绩效以：</span>
                  <template v-if="form.status == 30">
                    <el-radio v-model="radio" label="40">50%</el-radio>
                    <el-radio v-model="radio" label="50">100%</el-radio>
                  </template>
                  <template v-else>
                    <el-radio v-model="radio" label="40" v-if="form.status == 40">50%</el-radio>
                    <el-radio v-model="radio" label="50" v-if="form.status == 50">100%</el-radio>
                  </template>
                  <template v-if="form.status == 50">
                    <el-button type="success" plain disabled>已全部发放</el-button>
                  </template>
                  <template v-else>
                    <el-button
                      :disabled="options.editType == 2"
                      type="success"
                      @click="affirmFn"
                      plain
                      >确认发放</el-button
                    >
                  </template>
                </div></el-col
              >
            </el-row>
            <OpinionArea :id="options.id" :title="'生产绩效方案进度'"></OpinionArea>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="fullScreenOperation">
        <template v-if="options.editIndex == 0">
          <el-button type="primary" @click="submit('addOrSubmit', '保存并提交')" :loading="loading">
            保存并提交
          </el-button>
          <el-button
            type="success"
            @click="submit('productPerformanceAllocationAdd', '暂存')"
            :loading="loading"
          >
            暂存
          </el-button>
        </template>

        <template v-if="options.editIndex == 1 && options.editType != 2">
          <el-button
            v-if="this.form.status != 10"
            type="primary"
            @click="submit('undeterminedEditOrSubmit', '保存并提交')"
            :loading="loading"
          >
            保存并提交
          </el-button>
          <template v-if="options.editType == 1">
            <el-button
              v-if="this.form.status == 0"
              type="success"
              @click="submit('undeterminedEdit', '暂存')"
              :loading="loading"
            >
              暂存
            </el-button>
            <el-button
              type="primary"
              v-if="this.form.status == 10"
              @click="submit('productReject', '撤回')"
              >撤回</el-button
            >
          </template>
        </template>
        <template v-if="options.editIndex == 2 && options.editType != 2">
          <el-button type="success" @click="approve(2)" :loading="loading"> 通过 </el-button>
          <el-button type="danger" @click="approve(1)" :loading="loading"> 不通过 </el-button>
        </template>

        <el-button type="info" @click="returnFn">返回</el-button>
      </div>
    </div>

    <el-dialog append-to-body title="选择项目" width="70%" :visible.sync="dialogTableVisible">
      <div style="margin-bottom: 15px"></div>
      <el-table ref="multipleTable" border :data="projectData" v-loading="loading">
        <el-table-column align="center" type="index" label="操作" width="70">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="selectFn(scope.row)"> 选择</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="projectName"
          label="项目名称"
          :show-overflow-tooltip="false"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="deptClassifyManagerName"
          label="项目经理"
        ></el-table-column>
        <el-table-column prop="productPerformance" align="center" label="生产绩效(元)">
          <template slot-scope="scope">
            {{ scope.row.productPerformance | thousands }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="approveDialog"
      append-to-body
      width="30%"
      :before-close="handleClose"
      id="approveTxet"
    >
      <div slot="title" class="header-title" style="color: #47a6fb; font-size: 15px">
        <span v-if="approveIndex == 1"> {{ '审批不通过' }}</span>
        <span v-else> {{ '审批通过' }}</span>
      </div>
      <el-form :model="form" ref="rulesApproveTxet">
        <el-form-item
          v-show="approveIndex == 1"
          :rules="[{ required: true, message: '请填写不通过意见', trigger: 'blur' }]"
          prop="opinion"
        >
          <el-input
            label="请填写不通过意见"
            v-model="form.opinion"
            type="textarea"
            :rows="2"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="approveIndex == 2" prop="opinion">
          <el-input
            label="请填写通过意见"
            v-model="form.opinion"
            type="textarea"
            :rows="2"
            placeholder="通过"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="addApprove">保 存</el-button>
        <el-button
          type="warning"
          plain
          @click="handleClose"
          style="margin-right: 30%; margin-left: 12%"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    beforeOneType: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    isNum() {
      let show = false
      let num = 0
      this.form.productPerformanceDetailList.forEach(v => {
        num += Number(v.ratio)
      })
      if (num > 100) {
        show = true
      } else {
        show = false
      }
      return show
    },
    isnum() {
      let show = false
      let num = 0
      this.form.productPerformanceDetailList.forEach(v => {
        num += Number(v.ratio)
      })
      if (num < 100) {
        show = true
      } else {
        show = false
      }
      return show
    },
  },
  data() {
    return {
      showEditDialog: false,
      disabled: false,
      ProjectDisable: false,
      loading: false,
      approveDialog: false,
      dialogTableVisible: false,
      tableData: [],
      projectData: [],
      activeName: 0,
      form: {
        productPerformance: null,
        productPerformanceDetailList: [],
        projectId: null,
        opinion: '',
      },
      approveIndex: 0,
      radio: '40',
    }
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  inject: ['refresh'],
  created() {
    this.$api.dict
      .listSysDictData('OPINION_RECORD_CHECK', true)
      .then(res => {
        this.opinionType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        if (
          options.editIndex == 2 ||
          options.editType == 2 ||
          options.editIndex == 4 ||
          options.editIndex == 3
        ) {
          this.disabled = true
        } else if (options.editIndex == 0 || options.editIndex == 1) {
          this.disabled = false
        }
        this.init()
      },
    },
    form: {
      // immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (form) {
        this.ProjectDisable = false
        if (form.status == 10) {
          this.disabled = true
        } else if (form.status == 50) {
          this.radio = '50'
        }
        if (form.status == undefined) {
          this.ProjectDisable = false
        } else if (form.status != 0) {
          this.ProjectDisable = true
        }
      },
    },
    // beforeOneType: {
    //   immediate: true, //初始化立即执行
    //   handler: function(newVal) {
    //     // this.showEditDialog = newVal;
    //     // console.log(newVal);
    //   },
    // },
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
  },
  methods: {
    affirmFn() {
      let n = ''
      if (this.radio == '40') {
        n = '部分'
      } else {
        n = '全部'
      }

      this.$confirm(`确认发放${n}绩效, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          if (this.form.status == 30) {
            if (this.radio == '40') {
              this.$api.productionControl
                .affirmPortionApply({ id: this.options.id })
                .then(res => {
                  this.refresh(1)
                  this.returnFn()
                  this.$message({
                    type: 'success',
                    message: '操作成功!',
                  })
                  this.loading = false
                })
                .catch(err => {
                  console.log(err)
                  this.loading = false
                })
            } else {
              this.$api.productionControl
                .affirmApply({ id: this.options.id })
                .then(res => {
                  this.refresh(1)
                  this.returnFn()
                  this.$message({
                    type: 'success',
                    message: '操作成功!',
                  })
                  this.loading = false
                })
                .catch(err => {
                  console.log(err)
                  this.loading = false
                })
            }
          } else if (this.form.status == 40) {
            this.$api.productionControl
              .affirmApply({ id: this.options.id })
              .then(res => {
                this.refresh(1)
                this.returnFn()
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                })
                this.loading = false
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          })
        })
    },
    approve(n) {
      this.approveDialog = true
      this.approveIndex = n
    },
    init() {
      if (this.options.id) {
        this.loading = true
        this.$api.productionControl
          .selectDetailById(this.options.id)
          .then(res => {
            this.loading = false
            this.form = res.data
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
        this.$api.productionControl
          .opinionRecordList({ professionalId: this.options.id })
          .then(res => {
            this.loading = false
            this.opinionRecordList = res.data
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    addApprove() {
      let obj = {
        id: this.options.id,
        opinion: this.form.opinion,
      }
      let key = ''
      if (this.approveIndex == 1) {
        //不通过
        if (this.form.status && this.form.status == '10') {
          key = 'competentManagerReject'
        } else if (this.form.status && this.form.status == '20') {
          key = 'fzManagerReject'
        }
        this.$refs.rulesApproveTxet.validate(valid => {
          if (valid) {
            this.$api.productionControl[key](obj)
              .then(res => {
                this.loading = false
                this.handleClose()
                this.$message.success('操作成功！')
                this.refresh(1)
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        })
      } else if (this.approveIndex == 2) {
        if (this.form.status && this.form.status == '10') {
          key = 'competentManagerPass'
        } else if (this.form.status && this.form.status == '20') {
          key = 'fzManagerPass'
        }
        this.$api.productionControl[key](obj)
          .then(res => {
            this.loading = false
            this.handleClose()
            this.$message.success('操作成功！')
            this.refresh(1)
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },
    handleClose() {
      this.$refs.rulesApproveTxet.resetFields()
      this.form.opinion = ''
      this.approveDialog = false
    },
    addFn() {
      this.loading = true
      this.$api.productionControl
        .classifyManagerProjectList()
        .then(res => {
          this.loading = false
          this.projectData = res.data?.records
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.dialogTableVisible = true
    },
    inputFn(row, index) {
      this.$forceUpdate()
      let sum = (row.ratio / 100) * this.form.productPerformance
      sum = Number(sum).toFixed(2) //toFixed(2)数据项保留两位小数
      this.form.productPerformanceDetailList.forEach((v, i) => {
        if (i == index) {
          v.staffProductPerformance = Number(sum)
          v.ratio = v.ratio.replace(/[^\d.]/g, '').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        }
      })
    },
    selectFn(n) {
      this.form.productPerformanceDetailList = []
      this.$api.hour
        .getAllMahhoursByUserAndProjectNoPage({
          projectId: n.projectId,
        })
        .then(res => {
          this.form.productPerformance = n.productPerformance
          res.data.forEach(v => {
            let obj = {
              postId: v.postId,
              postName: v.postName,
              projectId: v.projectId,
              ratio: 0,
              ratioContent: '',
              staffHour: v.sumhour,
              staffId: v.userId,
              staffName: v.userName,
              staffProductPerformance: 0,
            }
            this.form.productPerformanceDetailList.push(obj)
          })

          this.form.projectName = n.projectName
          this.form.projectId = n.projectId
          this.disabled = false
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.dialogTableVisible = false
    },
    submit(key, text) {
      this.$confirm(`是否${text}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          if (key != 'addOrSubmit' && key != 'productPerformanceAllocationAdd') {
            this.form.projectId = this.options.projectId
            this.form.id = this.options.id
          }
          this.$api.productionControl[key](this.form)
            .then(res => {
              this.loading = false
              this.$message.success('操作成功！')
              this.refresh(1)
              this.returnFn()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          })
        })
    },
    returnFn() {
      this.form = {
        productPerformance: null,
        productPerformanceDetailList: [],
        projectId: null,
        opinion: '',
      }
      this.opinionRecordList = []
      this.$emit('update:isShow', false)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.Total_amount {
  display: inline-block;
  width: 15%;
  line-height: 40px;
  padding-left: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}
/deep/#checkOpinion {
  .el-form-item__label {
    font-size: 16px !important;
    color: red !important;
  }
}
</style>
